.article-shop-owner-wrapper {
  padding: 16px;
  border-radius: 4px;
  box-shadow: 0 1px 0 1px rgba(0, 0, 0, 0.05);
  background-color: @white;
  margin-bottom: 20px;
  .article-manager-shop-container {
    display: flex;
    justify-content: space-between;
    .manager-shop-title {
      font-size: 18px;
      font-weight: 700;
      line-height: 1.78;
      color: #26415d;
    }
    .change-manager-shop-btn {
      max-width: 140px;
    }
  }
  .shop-owner-info-wrapper {
    margin-top: 10px;
    .shop-owener-avatar-container {
      margin-right: 40px;
      .user-avatar {
        min-width: 96px;
        img {
          width: 96px;
          height: 96px;
          object-fit: cover;
          border-radius: 50%;
          border: 1px solid @pinkishGrey;
        }
      }
    }
    .shop-owner-info-detail-container {
      .shop-owner-name-email-phone-container {
        margin-bottom: 10px;
        .item-info-detail {
          width: 200px;
          padding-right: 10px;
          .title {
            font-size: 12px;
            line-height: 1.67;
            color: #26415d;
            font-weight: bold;
          }
          .content {
            font-size: 12px;
            line-height: 1.67;
            color: #26415d;
            word-break: break-all;
          }
        }
      }
      .shop-owner-address-container {
        .item-info-detail {
          .title {
            font-size: 12px;
            line-height: 1.67;
            color: #26415d;
            font-weight: bold;
          }
          .content {
            font-size: 12px;
            line-height: 1.67;
            color: #26415d;
            word-break: break-all;
          }
        }
      }
    }
  }
}

@primary-color: #26415d;@primaryFocus: #ae0d00;@error: #FF0D0D;@placeholder: #adaeb6;@text: #333749;@background: #f0f2f5;@border: #c7c7cc;@layout-header-height: 50px;@layout-sider-background: @primary-color;@menu-item-active-bg: @white;@menu-bg: @primary-color;@menu-item-color: @white;@font-family: 'Roboto', sans-serif;@white: #fff;@primaryHover: #fff;@dodgerBlue: #4b94f7;@warmGrey: #939393;@paleGrey: #f0f2f5;@warmGreyTwo: #707070;@black16: #29000000;@bubblegumPink: #ff80d9;@nightBlue: #06164b;@red: #ff0000;@text-color: #000000;@primaryBlue: #1000ff;@pinkishGrey: #ccc6c6;@warmGreyThree: #9f9a9a;@offYellow: #edf531;@veryLightPink: #fff3f3;@paleLilac: #e6e3ff;@news: #FF44B9;@supermarket: #F8FF44;@job: #BE4290;@transport: #CCCDB8;@tutorial: #BE4242;@rent: #42AEBE;@tourist: #D6C449;@restaurant: #42BE8C;@entertainment: #91BE42;@fonehouse: #30CB8C;@visa: #6742BE;@cosmetic: #0183E7;@tableRowHover: #f2f4f9;@disable: #c0c0c0;@black65: #595959;@warning: #FF7E0E;@success: #09CA51;@percentBlack40: rgba(0, 0, 0, 0.4);@lightGrayishNavy: #aeb5c6;@percent: rgba(255, 255, 255, 0.9);@black: #000000;@blueTag: #009cf5;@yellow: #f59b00;@green: #139a43;@orangeTag: #ff6700;@primaryShadow: #8c9ee8;@darkPastelGreen: #2FBE50;@goGreen: #15AE67;@Platinum: #ECEBEB;