.store-edit-component-wrapper {
  padding: 16px;
  border-radius: 4px;
  box-shadow: 0 1px 0 1px rgba(0, 0, 0, 0.05);
  background-color: @white;
  .store-edit-info-container {
    width: 100%;
    .info-section {
      .upload-image-container
        .ant-upload-picture-card-wrapper
        .ant-upload.ant-upload-select-picture-card {
        min-height: 100px;
        width: 160px;
        img {
          max-height: 100px;
        }
      }
    }
    .banner-section {
      .upload-image-container
        .ant-upload-picture-card-wrapper
        .ant-upload.ant-upload-select-picture-card {
        min-height: 190px;
        width: 365px;
        img {
          max-height: 190px;
        }
      }
    }
    .m-title-text {
      margin-bottom: 16px;
    }
    .ant-image-img {
      border-radius: 8px;
    }
    .store-info {
      margin-left: 16px;
      .info-row {
        .info-col {
          .s-text {
            &:first-child {
              font-weight: bold;
            }
          }
          .select-field-container {
            width: 100%;
            .ant-select-selector {
              height: 32px !important;
              .ant-select-selection-item {
                height: 24px !important;
              }
            }
            .ant-select-multiple .ant-select-selector {
              padding: 0 8px;
            }
          }
          .time-working-wrapper {
            display: flex;
            align-items: top;
            height: 30px;
            margin-bottom: 24px;

            .time-working-title {
              width: 60%;
            }
            .setting-icon {
              cursor: pointer;
              margin-top: 8px;
              margin-left: 10px;
            }
          }
        }
        .add-location-btn {
          flex: 0 0 32px;
          height: 32px;
          margin-left: 8px;
          padding: 0;
          border-radius: 100%;
        }
      }
    }
  }
  .business-description-container {
    margin: 16px 0px 0px;
    display: flex;
    flex-direction: column;
    width: 100%;
    .title {
      color: @black;
      font-size: 16px;
      font-weight: bold;
      margin-bottom: 16px;
    }
    .editor-container {
      .ant-form-item-control-input .quill .ql-container .ql-editor {
        max-height: 600px;
        min-height: 600px;
      }
    }
  }
}

@primary-color: #26415d;@primaryFocus: #ae0d00;@error: #FF0D0D;@placeholder: #adaeb6;@text: #333749;@background: #f0f2f5;@border: #c7c7cc;@layout-header-height: 50px;@layout-sider-background: @primary-color;@menu-item-active-bg: @white;@menu-bg: @primary-color;@menu-item-color: @white;@font-family: 'Roboto', sans-serif;@white: #fff;@primaryHover: #fff;@dodgerBlue: #4b94f7;@warmGrey: #939393;@paleGrey: #f0f2f5;@warmGreyTwo: #707070;@black16: #29000000;@bubblegumPink: #ff80d9;@nightBlue: #06164b;@red: #ff0000;@text-color: #000000;@primaryBlue: #1000ff;@pinkishGrey: #ccc6c6;@warmGreyThree: #9f9a9a;@offYellow: #edf531;@veryLightPink: #fff3f3;@paleLilac: #e6e3ff;@news: #FF44B9;@supermarket: #F8FF44;@job: #BE4290;@transport: #CCCDB8;@tutorial: #BE4242;@rent: #42AEBE;@tourist: #D6C449;@restaurant: #42BE8C;@entertainment: #91BE42;@fonehouse: #30CB8C;@visa: #6742BE;@cosmetic: #0183E7;@tableRowHover: #f2f4f9;@disable: #c0c0c0;@black65: #595959;@warning: #FF7E0E;@success: #09CA51;@percentBlack40: rgba(0, 0, 0, 0.4);@lightGrayishNavy: #aeb5c6;@percent: rgba(255, 255, 255, 0.9);@black: #000000;@blueTag: #009cf5;@yellow: #f59b00;@green: #139a43;@orangeTag: #ff6700;@primaryShadow: #8c9ee8;@darkPastelGreen: #2FBE50;@goGreen: #15AE67;@Platinum: #ECEBEB;