.messenger-page {
  background-color: #edf0f5;
  height: 100%;
  // padding: 16px;
  .news-fonehouse-list-header {
    .ant-typography {
        padding: 16px;
      margin-bottom: 0;
      background-color: @white;
      width: 100%;
    }
    .ant-btn {
      max-width: 100px;
    }
  }
  .message-page-content {
    display: flex;
    margin: 16px;
    background-color: @white;
    height: calc(100vh - 142px);
  }
  .conversation-list-container {
    width: 100%;
    max-width: 366px;
    overflow: auto;
  }
  .conversation-container {
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow: hidden;
    .message-list-container {
      flex: 1;
    }
    .message-input-container {
      padding: 8px;
      border-top: 1px solid rgba(53, 62, 58, 0.1);
      .ant-form-item-explain {
        display: none;
      }
    }
  }
}
@primary-color: #26415d;@primaryFocus: #ae0d00;@error: #FF0D0D;@placeholder: #adaeb6;@text: #333749;@background: #f0f2f5;@border: #c7c7cc;@layout-header-height: 50px;@layout-sider-background: @primary-color;@menu-item-active-bg: @white;@menu-bg: @primary-color;@menu-item-color: @white;@font-family: 'Roboto', sans-serif;@white: #fff;@primaryHover: #fff;@dodgerBlue: #4b94f7;@warmGrey: #939393;@paleGrey: #f0f2f5;@warmGreyTwo: #707070;@black16: #29000000;@bubblegumPink: #ff80d9;@nightBlue: #06164b;@red: #ff0000;@text-color: #000000;@primaryBlue: #1000ff;@pinkishGrey: #ccc6c6;@warmGreyThree: #9f9a9a;@offYellow: #edf531;@veryLightPink: #fff3f3;@paleLilac: #e6e3ff;@news: #FF44B9;@supermarket: #F8FF44;@job: #BE4290;@transport: #CCCDB8;@tutorial: #BE4242;@rent: #42AEBE;@tourist: #D6C449;@restaurant: #42BE8C;@entertainment: #91BE42;@fonehouse: #30CB8C;@visa: #6742BE;@cosmetic: #0183E7;@tableRowHover: #f2f4f9;@disable: #c0c0c0;@black65: #595959;@warning: #FF7E0E;@success: #09CA51;@percentBlack40: rgba(0, 0, 0, 0.4);@lightGrayishNavy: #aeb5c6;@percent: rgba(255, 255, 255, 0.9);@black: #000000;@blueTag: #009cf5;@yellow: #f59b00;@green: #139a43;@orangeTag: #ff6700;@primaryShadow: #8c9ee8;@darkPastelGreen: #2FBE50;@goGreen: #15AE67;@Platinum: #ECEBEB;