@import 'styles/theme';
.type-block-wrapper {
  width: 20px;
  height: 20px;
  border-radius: 4px;
}

.type-block-wrapper.news-block {
  background-color: @news;
}
.type-block-wrapper.supermarket-block {
  background-color: @supermarket;
}
.type-block-wrapper.jobs-block {
  background-color: @job;
}
.type-block-wrapper.transport-block {
  background-color: @transport;
}
.type-block-wrapper.tutorial-block {
  background-color: @tutorial;
}
.type-block-wrapper.rent-block {
  background-color: @rent;
}
.type-block-wrapper.visa-block {
  background-color: @visa;
}
.type-block-wrapper.tourist-block {
  background-color: @tourist;
}
.type-block-wrapper.restaurant-block {
  background-color: @restaurant;
}
.type-block-wrapper.entertainment-block {
  background-color: @entertainment;
}
.type-block-wrapper.fonehouse-block {
  background-color: @fonehouse;
}
.type-block-wrapper.cosmetic-block {
  background-color: @cosmetic;
}
.type-block-wrapper.car-and-old-stuff-block {
  background-color: #CB8930;
}
.type-block-wrapper.other {
  background-color: #4CBC9A;
}

@primary-color: #26415d;@primaryFocus: #ae0d00;@error: #FF0D0D;@placeholder: #adaeb6;@text: #333749;@background: #f0f2f5;@border: #c7c7cc;@layout-header-height: 50px;@layout-sider-background: @primary-color;@menu-item-active-bg: @white;@menu-bg: @primary-color;@menu-item-color: @white;@font-family: 'Roboto', sans-serif;@white: #fff;@primaryHover: #fff;@dodgerBlue: #4b94f7;@warmGrey: #939393;@paleGrey: #f0f2f5;@warmGreyTwo: #707070;@black16: #29000000;@bubblegumPink: #ff80d9;@nightBlue: #06164b;@red: #ff0000;@text-color: #000000;@primaryBlue: #1000ff;@pinkishGrey: #ccc6c6;@warmGreyThree: #9f9a9a;@offYellow: #edf531;@veryLightPink: #fff3f3;@paleLilac: #e6e3ff;@news: #FF44B9;@supermarket: #F8FF44;@job: #BE4290;@transport: #CCCDB8;@tutorial: #BE4242;@rent: #42AEBE;@tourist: #D6C449;@restaurant: #42BE8C;@entertainment: #91BE42;@fonehouse: #30CB8C;@visa: #6742BE;@cosmetic: #0183E7;@tableRowHover: #f2f4f9;@disable: #c0c0c0;@black65: #595959;@warning: #FF7E0E;@success: #09CA51;@percentBlack40: rgba(0, 0, 0, 0.4);@lightGrayishNavy: #aeb5c6;@percent: rgba(255, 255, 255, 0.9);@black: #000000;@blueTag: #009cf5;@yellow: #f59b00;@green: #139a43;@orangeTag: #ff6700;@primaryShadow: #8c9ee8;@darkPastelGreen: #2FBE50;@goGreen: #15AE67;@Platinum: #ECEBEB;