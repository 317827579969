.market-product-modal-wrapper {
  .ant-modal-content {
    .ant-modal-close {
      left: 0px;
    }
    .ant-modal-header {
      border-bottom: none;
    }
    .ant-modal-title {
      font-size: 18px;
      font-weight: bold;
      line-height: 24px;
      color: @black;
      padding-left: 30px;
    }
    .ant-modal-body {
      padding: 10px 16px 30px;
    }
  }
  .market-product-detail-container {
    display: flex;
    .market-product-thumbnail {
      position: relative;
      border-radius: 6px;
      .ant-image {
        .ant-image-img {
          width: 100%;
          height: 100%;
          object-fit: contain;
          border-radius: 6px;
        }
      }
      .sale-price-wrapper {
        position: absolute;
        top: 0;
        left: 0;
        background-color: red;
        padding: 8px 10px;
        color: white;
        font-weight: bold;
        font-size: 12px;
        border-top-left-radius: 6px;
        border-bottom-right-radius: 6px;
      }
    }
    .market-product-detail-info {
      flex: 1;
      margin-left: 24px;
      .detail-info-wrapper {
        .detail-info-row {
          margin-top: 10px;
          .info-col {
            padding-right: 8px;
            color: @primary-color;
            .title-text {
              font-weight: bold;
            }
          }
        }
      }
      .edit-remove-product-actions {
        margin-top: 24px;
        display: flex;
        .ant-btn {
          font-size: 14px;
          font-weight: bold;
        }
        .ant-btn-dangerous {
          background-color: #ff0000;
        }
      }
    }
  }
  .market-product-form-container {
    display: flex;
    .upload-image-container
      .ant-upload-picture-card-wrapper
      .ant-upload.ant-upload-select-picture-card {
      min-height: 140px;
      width: 200px;
      .ant-upload {
        .uploaded-image {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
    .market-product-form-info {
      flex: 1;
      margin-left: 16px;
      .info-row {
        display: flex;
        justify-content: space-between;
        width: 100%;
        .item-title {
          color: @primary-color;
          font-weight: bold;
        }
        .select-field-container {
          .ant-select-selector {
            height: 32px;
          }
        }
        .add-new-product-type {
          display: flex;
          justify-content: space-between;
          .plus-icon {
            width: 24px;
            height: 100%;
          }
        }
      }
      .promotion-info-row {
        margin-bottom: 10px;
        .promotion {
          margin-left: 10px;
          color: @primary-color;
        }
      }
      .save-back-product-actions {
        display: flex;
        justify-content: space-between;
        .ant-btn {
          width: 40%;
        }
      }
      .add-product-actions {
        .ant-btn {
          width: 100%;
        }
      }
    }
  }
}

@primary-color: #26415d;@primaryFocus: #ae0d00;@error: #FF0D0D;@placeholder: #adaeb6;@text: #333749;@background: #f0f2f5;@border: #c7c7cc;@layout-header-height: 50px;@layout-sider-background: @primary-color;@menu-item-active-bg: @white;@menu-bg: @primary-color;@menu-item-color: @white;@font-family: 'Roboto', sans-serif;@white: #fff;@primaryHover: #fff;@dodgerBlue: #4b94f7;@warmGrey: #939393;@paleGrey: #f0f2f5;@warmGreyTwo: #707070;@black16: #29000000;@bubblegumPink: #ff80d9;@nightBlue: #06164b;@red: #ff0000;@text-color: #000000;@primaryBlue: #1000ff;@pinkishGrey: #ccc6c6;@warmGreyThree: #9f9a9a;@offYellow: #edf531;@veryLightPink: #fff3f3;@paleLilac: #e6e3ff;@news: #FF44B9;@supermarket: #F8FF44;@job: #BE4290;@transport: #CCCDB8;@tutorial: #BE4242;@rent: #42AEBE;@tourist: #D6C449;@restaurant: #42BE8C;@entertainment: #91BE42;@fonehouse: #30CB8C;@visa: #6742BE;@cosmetic: #0183E7;@tableRowHover: #f2f4f9;@disable: #c0c0c0;@black65: #595959;@warning: #FF7E0E;@success: #09CA51;@percentBlack40: rgba(0, 0, 0, 0.4);@lightGrayishNavy: #aeb5c6;@percent: rgba(255, 255, 255, 0.9);@black: #000000;@blueTag: #009cf5;@yellow: #f59b00;@green: #139a43;@orangeTag: #ff6700;@primaryShadow: #8c9ee8;@darkPastelGreen: #2FBE50;@goGreen: #15AE67;@Platinum: #ECEBEB;