.upload-image-container {
  .ant-upload-picture-card-wrapper {
    .ant-upload.ant-upload-select-picture-card {
      min-height: 265px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      .ant-upload {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        padding: 8px;
        .uploaded-image {
          position: static;
          display: block;
          width: 100%;
          height: 100%;
          object-fit: contain;
          max-width: 330px;
        }
      }
    }
  }
}

.antd-img-crop-modal {
  .ant-modal-content {
    .ant-modal-footer {
      display: flex;
      button {
        max-width: calc((100% - 4px)/2);
      }
    }
  }
}
.date-picker-container {
  .ant-picker {
    width: 100%;
  }
}

.input-container {
  .suggestion-item {
    &:not(:last-child) {border-bottom: 1px solid @primary-color;}
  }
  .suggestion-item--active {
    background-color: @tableRowHover;
  }
  .autocomplete-dropdown-container {
    position: absolute;
    background-color: @white;
    border: 1px solid @primary-color;
    border-top: unset;
    z-index: 2;
    padding: 8px;
    .suggestion-item {
      &:not(:last-child) {border-bottom: 1px solid @primary-color;}
    }
    .suggestion-item--active {
      background-color: @tableRowHover;
    }
  }
  &.avatar-field {
    display: flex;
    width: 128px;
    margin: auto;

    .avatar-overlay {
      cursor: pointer;
      background-color: @percentBlack40;
      position: absolute;
      width: 128px;
      height: 128px;
      border-radius: 50%;
      z-index: 1;
      color: @white;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    
  }
  .ant-input-lg {
    height: 48px;
  }
  .ant-input {
    font-size: 18px;
    border-radius: 8px;
    padding-left: 16px;
    padding-right: 16px;
    border: @lightGrayishNavy 1px solid;
    background-color: @percent;
  }
  input.ant-input.ant-input-disabled {
    color: @black;
    background-color: #E0E0E0;
  }
  .ant-input-suffix,
  .ant-input-prefix {
    img {
      width: 24px;
      height: 24px;
    }
  }
  .ant-form-item-label {
    font-size: 10px;
    font-weight: 500;
    line-height: 1.5;
  }

  .ant-form-item-control-input {
    .ant-form-item-control-input-content {
      .ant-input-affix-wrapper {
        border-radius: 8px;
        padding-left: 16px;
        padding-right: 16px;
        height: 48px;
        .ant-input {
          height: 32px;
        }
      }
    }
  }

  .ant-form-item-explain {
    font-size: 12px;
    .error-text {
      margin-top: 2px;
      text-align: left;
    }
  }

  .label-wrapper {
    display: flex;
    font-size: 18px;
    align-items: center;
    margin-bottom: 8px;
    color: @black;
    font-weight: bold;
    .required-mark {
      margin-left: 4px;
      color: @error;
    }
  }
  .notes-wrapper {
    align-items: center;
    margin-bottom: 8px;
    background-color: fade(@blueTag, 10%);
    border-radius: 4px;
    font-size: 10px;
    border-left: solid 4px @blueTag;
    padding: 8px 16px 8px 12px;
    color: @black;
  }
  .no-error-form-item {
    margin-bottom: 8px;
  }
}


.select-field-container {
  .ant-row {
    .ant-form-item-control-input-content .ant-select .ant-select-selector {
      display: flex;
      align-items: center;
      height: 48px;
      border-radius: 8px;
      border: solid 1px @lightGrayishNavy;
      background-color: @percent;
      padding: 0 16px;
      .ant-select-selection-item {
        display: flex;
        align-items: center;
        .ant-avatar {
          margin-right: 9px;
        }
        .icon-option {
          font-size: 14px;
        }
        .no-icon-option {
          font-size: 18px;
        }
      }
    }
    .ant-select-arrow {
      right: 21px;
    }
    .ant-select-dropdown {
      .ant-select-item-option-content {  
        display: flex;
        align-items: center;
        .ant-avatar {
          margin-right: 9px;
        }
        .icon-option {
          font-size: 14px;
        }
        .no-icon-option {
          font-size: 18px;
        }
      }
    }
    .ant-select-clear {
      right: 21px;
    }
  }
}

.input-container.multi-upload-image-container {
  .ant-upload.ant-upload-select-picture-card {
    border: none;
  }
  .ant-upload-list-item-card-actions-btn.ant-btn-sm {
    margin-top: 16px;
  }
}

.date-picker-container {
  .ant-picker-large {
    height: 48px;
  }
  .ant-picker {
    width: 100%;
    border-radius: 8px;
    border: @lightGrayishNavy 1px solid;
    padding-left: 16px;
    padding-right: 16px;
    .ant-picker-input {
      input {
        font-size: 18px;
      }
    }
  }
}

.autocomplete-container {
  position: relative;
  .autocomplete-dropdown-container {
    position: absolute;
    background-color: @white;
    border: 1px solid @primary-color;
    border-top: unset;
    z-index: 2;
    padding: 8px;
    .suggestion-item {
      &:not(:last-child) {border-bottom: 1px solid @primary-color;}
    }
    .suggestion-item--active {
      background-color: @tableRowHover;
    }
  }
}

.multi-upload-video-container {
  .ant-upload-picture-card-wrapper {
    .ant-upload-list {
      .ant-upload-list-picture-card-container {
        height: 150px;
        width: 200px;
      }
    }
  }
}
@primary-color: #26415d;@primaryFocus: #ae0d00;@error: #FF0D0D;@placeholder: #adaeb6;@text: #333749;@background: #f0f2f5;@border: #c7c7cc;@layout-header-height: 50px;@layout-sider-background: @primary-color;@menu-item-active-bg: @white;@menu-bg: @primary-color;@menu-item-color: @white;@font-family: 'Roboto', sans-serif;@white: #fff;@primaryHover: #fff;@dodgerBlue: #4b94f7;@warmGrey: #939393;@paleGrey: #f0f2f5;@warmGreyTwo: #707070;@black16: #29000000;@bubblegumPink: #ff80d9;@nightBlue: #06164b;@red: #ff0000;@text-color: #000000;@primaryBlue: #1000ff;@pinkishGrey: #ccc6c6;@warmGreyThree: #9f9a9a;@offYellow: #edf531;@veryLightPink: #fff3f3;@paleLilac: #e6e3ff;@news: #FF44B9;@supermarket: #F8FF44;@job: #BE4290;@transport: #CCCDB8;@tutorial: #BE4242;@rent: #42AEBE;@tourist: #D6C449;@restaurant: #42BE8C;@entertainment: #91BE42;@fonehouse: #30CB8C;@visa: #6742BE;@cosmetic: #0183E7;@tableRowHover: #f2f4f9;@disable: #c0c0c0;@black65: #595959;@warning: #FF7E0E;@success: #09CA51;@percentBlack40: rgba(0, 0, 0, 0.4);@lightGrayishNavy: #aeb5c6;@percent: rgba(255, 255, 255, 0.9);@black: #000000;@blueTag: #009cf5;@yellow: #f59b00;@green: #139a43;@orangeTag: #ff6700;@primaryShadow: #8c9ee8;@darkPastelGreen: #2FBE50;@goGreen: #15AE67;@Platinum: #ECEBEB;