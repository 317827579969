.customize-product-type-modal {
  .product-type-modal-container {
    .product-type-list-wrapper {
      border-right: 1px solid @warmGreyTwo;
      padding-right: 12px;
      display: flex;
      flex-direction: column;
      .product-type-list-container {
        .type-title {
          font-weight: bold;
          font-size: 18px;
          margin-bottom: 4px;
        }
        .sortable-list-wrapper {
          display: flex;
          flex-wrap: wrap;
          overflow: auto;
          .product-type-item-wrapper {
            flex: 0 0 30%;
            box-shadow: 0px 3px 6px #00000029;
            border-radius: 6px;
            margin: 4px;
            .handle-item-wrapper {
              display: flex;
              padding: 8px;
              align-items: center;
              width: 100%;
              .type-image {
                height: 28px;
                display: flex;
                align-items: center;
                margin-right: 4px;
              }
            }
          }
        }
      }
      .product-machine-series-list-container {
        .type-title {
          font-weight: bold;
          font-size: 18px;
          margin: 10px 0px 4px;
        }
      }
      .product-machine-image-list-container {
        .label-wrapper {
          margin: 10px 0px 4px;
        }
        .ant-upload-list-picture-card-container {
          width: 100px;
          height: 100px;
          margin: 0 4px 4px 0;
        }
        .ant-upload-select-picture-card {
          width: 100px;
          height: 100px;
        }
      }
    }
    .product-type-form-wrapper {
      padding-left: 24px;
      display: flex;
      flex-direction: column;
      .create-type-form-wrapper {
        .type-form {
          .ant-row {
            .create-type-image {
              padding-top: 28px;
              .upload-image-container {
                .ant-upload-picture-card-wrapper {
                  .ant-upload-select-picture-card {
                    min-height: 82px;
                    height: auto;
                  }
                }
                .ant-btn {
                  background-color: #e0e0e0;
                  border-radius: 8px;
                  border-color: transparent;
                  color: @black;
                  font-size: 14px;
                }
              }
            }
          }
        }
      }
      .create-machine-series-form-wrapper {
        padding-top: 20px;
       
        .machine-series-form {
          .ant-row {
            .create-type-image {
              padding-top: 72px;
              .upload-image-container {
                .ant-upload-picture-card-wrapper {
                  .ant-upload-select-picture-card {
                    min-height: 82px;
                    height: auto;
                  }
                }
                .ant-btn {
                  background-color: #e0e0e0;
                  border-radius: 8px;
                  border-color: transparent;
                  color: @black;
                  font-size: 14px;
                }
              }
            }
            .multi-upload-image-container {
              .ant-upload-picture-card-wrapper {
                .ant-upload-list-picture-card {
                  .ant-upload-list-picture-card-container {
                    .ant-upload-list-item-actions {
                      display: flex;
                      width: 100%;
                      height: 100%;
                      display: flex;
                      justify-content: center;
                      align-items: center;
                      a {
                        position: relative;
                        width: 80%;
                        height: 80%;
                        .anticon-eye {
                          svg {
                            display: none;
                          }
                        }
                      }
                      .ant-btn-icon-only {
                        position: absolute;
                        top: 0px;
                        right: 0px;
                        margin-top: 0px;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
// When the Article Item is dragged, it gets removed from the parent container, so this item needs to be styled separately.
.product-type-item-wrapper {
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 6px;
  width: 30%;
  float: left;
  margin: 4px;
  background-color: @white;
  cursor: pointer;
  position: relative;
  .handle-item-wrapper {
    width: 100%;
    display: flex;
    padding: 8px;
    align-items: center;
    img {
      width: 32px;
    }
    .type-image {
      height: 28px;
      display: flex;
      align-items: center;
      margin-right: 4px;
    }
  }
  .icon-close {
    position: absolute;
    top: -4px;
    right: -4px;
    z-index: 10;
    color: @white;
    background-color: @warmGreyTwo;
  }
}
.item-selected {
  background-color: #04ffcd;
}

@primary-color: #26415d;@primaryFocus: #ae0d00;@error: #FF0D0D;@placeholder: #adaeb6;@text: #333749;@background: #f0f2f5;@border: #c7c7cc;@layout-header-height: 50px;@layout-sider-background: @primary-color;@menu-item-active-bg: @white;@menu-bg: @primary-color;@menu-item-color: @white;@font-family: 'Roboto', sans-serif;@white: #fff;@primaryHover: #fff;@dodgerBlue: #4b94f7;@warmGrey: #939393;@paleGrey: #f0f2f5;@warmGreyTwo: #707070;@black16: #29000000;@bubblegumPink: #ff80d9;@nightBlue: #06164b;@red: #ff0000;@text-color: #000000;@primaryBlue: #1000ff;@pinkishGrey: #ccc6c6;@warmGreyThree: #9f9a9a;@offYellow: #edf531;@veryLightPink: #fff3f3;@paleLilac: #e6e3ff;@news: #FF44B9;@supermarket: #F8FF44;@job: #BE4290;@transport: #CCCDB8;@tutorial: #BE4242;@rent: #42AEBE;@tourist: #D6C449;@restaurant: #42BE8C;@entertainment: #91BE42;@fonehouse: #30CB8C;@visa: #6742BE;@cosmetic: #0183E7;@tableRowHover: #f2f4f9;@disable: #c0c0c0;@black65: #595959;@warning: #FF7E0E;@success: #09CA51;@percentBlack40: rgba(0, 0, 0, 0.4);@lightGrayishNavy: #aeb5c6;@percent: rgba(255, 255, 255, 0.9);@black: #000000;@blueTag: #009cf5;@yellow: #f59b00;@green: #139a43;@orangeTag: #ff6700;@primaryShadow: #8c9ee8;@darkPastelGreen: #2FBE50;@goGreen: #15AE67;@Platinum: #ECEBEB;