@import "styles/theme.less";

.decentralization-popup-wrapper {
  max-width: 580px !important;
  width: 100% !important;
  display: flex;
  .ant-modal-content {
    .ant-modal-body {
      .decentralization-popup-container {
        .decentralization-popup-header {
          display: flex;
          align-items: center;
          .icon-close {
            width: 20px;
            height: 20px;
            margin-right: 10px;
            cursor: pointer;
          }
          .header-content {
            font-weight: bold;
            font-size: 18px;
          }
        }
        .decentralization-popup-body {
          display: flex;
          margin-top: 16px;
          .user-info-container {
            flex: 1;
            .title {
              font-weight: bold;
              font-size: 14px;
            }
            .user-info-detail {
              display: flex;
              margin-top: 10px;
              .user-avatar {
                min-width: 48px;
                img {
                  width: 48px;
                  height: 48px;
                  object-fit: cover;
                  border: thin solid #b9b9b9;
                  border-radius: 50%;
                }
              }
              .user-name-contact-contaier {
                margin: 0px 8px;
                .user-name {
                  font-weight: bold;
                  font-size: 16px;
                  line-height: 1.25;
                  margin-bottom: 4px;
                }
                .user-contact {
                  font-size: 12px;
                  line-height: 14px;
                  .phone {
                    margin-bottom: 4px;
                  }
                }
              }
            }
          }
          .roles-list-container {
            background: #ffffff 0% 0% no-repeat padding-box;
            box-shadow: 0px 3px 6px #00000029;
            border-radius: 8px;
            flex: 1;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            padding: 10px;
            .decentralization-form {
              .checkbox-container {
                display: flex;
                flex-wrap: wrap;
                flex-direction: column;
                max-height: 160px;
                gap: 4px 0;
                .ant-checkbox-wrapper {
                  flex: 0 1 50%;
                  margin: 0,
                }
              }
            }

            .decentralization-btn {
              background: #26415d 0% 0% no-repeat padding-box;
              box-shadow: 0px 3px 6px #00000029;
              border: 0.10000000149011612px solid #707070;
              border-radius: 6px;
              color: #fff;
              text-align: center;
              padding: 4px 0px;
              margin-top: 20px;
            }
          }
        }
      }
    }
  }
}

@primary-color: #26415d;@primaryFocus: #ae0d00;@error: #FF0D0D;@placeholder: #adaeb6;@text: #333749;@background: #f0f2f5;@border: #c7c7cc;@layout-header-height: 50px;@layout-sider-background: @primary-color;@menu-item-active-bg: @white;@menu-bg: @primary-color;@menu-item-color: @white;@font-family: 'Roboto', sans-serif;@white: #fff;@primaryHover: #fff;@dodgerBlue: #4b94f7;@warmGrey: #939393;@paleGrey: #f0f2f5;@warmGreyTwo: #707070;@black16: #29000000;@bubblegumPink: #ff80d9;@nightBlue: #06164b;@red: #ff0000;@text-color: #000000;@primaryBlue: #1000ff;@pinkishGrey: #ccc6c6;@warmGreyThree: #9f9a9a;@offYellow: #edf531;@veryLightPink: #fff3f3;@paleLilac: #e6e3ff;@news: #FF44B9;@supermarket: #F8FF44;@job: #BE4290;@transport: #CCCDB8;@tutorial: #BE4242;@rent: #42AEBE;@tourist: #D6C449;@restaurant: #42BE8C;@entertainment: #91BE42;@fonehouse: #30CB8C;@visa: #6742BE;@cosmetic: #0183E7;@tableRowHover: #f2f4f9;@disable: #c0c0c0;@black65: #595959;@warning: #FF7E0E;@success: #09CA51;@percentBlack40: rgba(0, 0, 0, 0.4);@lightGrayishNavy: #aeb5c6;@percent: rgba(255, 255, 255, 0.9);@black: #000000;@blueTag: #009cf5;@yellow: #f59b00;@green: #139a43;@orangeTag: #ff6700;@primaryShadow: #8c9ee8;@darkPastelGreen: #2FBE50;@goGreen: #15AE67;@Platinum: #ECEBEB;