.product-create-form-wrapper {
  form {
    .form-grid {
      > .ant-col:nth-child(2n + 1) {
        padding-right: 24px;
        border-right: 1px solid #707070;
      }
      > .ant-col:nth-child(2n) {
        padding-left: 24px;
      }
      .software-version-wrapper {
        display: flex;
        flex-direction: column;
        .label {
          display: flex;
          font-size: 18px;
          align-items: center;
          margin-bottom: 8px;
          color: #000000;
          font-weight: bold;
        }
      }
    }
    .button-wrapper {
      > .ant-col {
        width: 100%;
        > button {
          width: calc((100% - 8px));
        }
      }
    }
    .ant-col {
      .multi-upload-image-container {
        .ant-upload-picture-card-wrapper {
          .ant-upload-list-picture-card {
            .ant-upload-list-picture-card-container {
              .ant-upload-list-item-actions {
                display: flex;
                width: 100%;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                a {
                  position: relative;
                  width: 80%;
                  height: 80%;
                  .anticon-eye {
                    svg {
                      display: none;
                    }
                  }
                }
                .ant-btn-icon-only {
                  position: absolute;
                  top: 0px;
                  right: 0px;
                  margin-top: 0px;
                }
              }
            }
          }
        }
      }
      .machine-image-list-wrapper {
        margin-bottom: 24px;
        .machine-image-item {
          float: left;
          width: 80px;
          height: 80px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 6px;
          margin: 4px;
          box-shadow: 0px 3px 6px #00000029;
          background-color: @white;
          padding: 8px;
          img {
            width: 100%;
          }
        }
        .selected-image {
          border: 2px solid #21f00a;
        }
      }
    }
  }
}

@primary-color: #26415d;@primaryFocus: #ae0d00;@error: #FF0D0D;@placeholder: #adaeb6;@text: #333749;@background: #f0f2f5;@border: #c7c7cc;@layout-header-height: 50px;@layout-sider-background: @primary-color;@menu-item-active-bg: @white;@menu-bg: @primary-color;@menu-item-color: @white;@font-family: 'Roboto', sans-serif;@white: #fff;@primaryHover: #fff;@dodgerBlue: #4b94f7;@warmGrey: #939393;@paleGrey: #f0f2f5;@warmGreyTwo: #707070;@black16: #29000000;@bubblegumPink: #ff80d9;@nightBlue: #06164b;@red: #ff0000;@text-color: #000000;@primaryBlue: #1000ff;@pinkishGrey: #ccc6c6;@warmGreyThree: #9f9a9a;@offYellow: #edf531;@veryLightPink: #fff3f3;@paleLilac: #e6e3ff;@news: #FF44B9;@supermarket: #F8FF44;@job: #BE4290;@transport: #CCCDB8;@tutorial: #BE4242;@rent: #42AEBE;@tourist: #D6C449;@restaurant: #42BE8C;@entertainment: #91BE42;@fonehouse: #30CB8C;@visa: #6742BE;@cosmetic: #0183E7;@tableRowHover: #f2f4f9;@disable: #c0c0c0;@black65: #595959;@warning: #FF7E0E;@success: #09CA51;@percentBlack40: rgba(0, 0, 0, 0.4);@lightGrayishNavy: #aeb5c6;@percent: rgba(255, 255, 255, 0.9);@black: #000000;@blueTag: #009cf5;@yellow: #f59b00;@green: #139a43;@orangeTag: #ff6700;@primaryShadow: #8c9ee8;@darkPastelGreen: #2FBE50;@goGreen: #15AE67;@Platinum: #ECEBEB;