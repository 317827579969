.ant-layout {
  .affix-nav {
    .ant-layout-header {
      padding: 0;
      .header-content {
        width: 100%;
        background-color: @white;
        display: flex;
        justify-content: space-between;
        align-items: center;
        box-shadow: 0 1px 4px 0 rgba(0, 21, 41, 0.12);
        position: relative;
        .full-flex {
          .user-menu {
            cursor: pointer;
            display: flex;
            margin-left: 8px;
            .anticon-caret-down {
              margin: 0 8px;
            }
          }
        }
      }
    }
  }
  .layout-content {
    min-height: calc(100% - 50px);
  }
  .menu-wrapper {
    .ant-layout-sider-children {
      .ant-menu {
        background-color: @primary-color;
        .ant-menu-submenu-selected {
          color: @white;
        }
        .ant-menu-submenu-active.ant-menu-submenu-selected {
          color: @primary-color;
        }
        .ant-menu-item-active {
          color: @white;
        }
        .ant-menu-item-active.ant-menu-item-selected {
          color: @primary-color;        
        }
        
        .ant-menu-submenu-active {
          div {
            color: @white;
          }
        }
        .ant-menu-item {
          .ant-menu-title-content {
            display: flex;
            align-items: center;
          }
          .unread-news-number {
            margin-left: 10px;
            background-color: @red;
            width: 18px;
            height: 18px;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 10px;
            color: @white;
          }
        }
      }

    }
  }
}

.ant-menu-submenu-title {
  a {
    color: rgba(0, 0, 0, 0.65);
  }
}

.ant-menu-inline .ant-menu-item,
.ant-menu-inline .ant-menu-submenu-title {
  width: 100%;
}

.ant-layout-sider {
  background-color: @white;
}

.menu-wrapper {
  min-height: 100vh;
  box-shadow: 2px 0 6px 0 rgba(0, 21, 41, 0.35);

  & > div {
    height: 100%;
  }
}
.menu-wrapper {
  .aside-menu {
    overflow: auto;
    min-height: 100%;
    left: 0;
    box-shadow: 2px 0 8px 0 rgba(29, 35, 41, 0.05);
    z-index: 15;
    .ant-layout-sider-children {
      overflow: hidden;
      .ant-menu-root {
        height: calc(100vh - 100px);
        overflow: auto;
        overflow-x: hidden;
      }
    }
    i.anticon {
      transform: translate(0px, 2px);
    }
  }
}

.side-menu-logo {
  width: 100%;
  padding: 7px;
  display: flex;
  justify-content: center;
  align-items: center;
  .logo-text {
    margin-left: 8px;
    font-size: 18px;
    font-weight: 600;
    color: @white;
  }
}

.toggle-collapse-btn {
  margin: auto 24px;
}

.custom-layout-footer {
  display: flex;
  justify-content: space-between;

  max-width: 317px;
  margin-left: auto;
  margin-right: auto;
  font-size: 14px;

  * {
    color: rgba(0, 0, 0, 0.45);
  }
}

.user-setting-menu {
  .ant-menu-item.ant-menu-item-active {
    color: @white;
  }
}

@primary-color: #26415d;@primaryFocus: #ae0d00;@error: #FF0D0D;@placeholder: #adaeb6;@text: #333749;@background: #f0f2f5;@border: #c7c7cc;@layout-header-height: 50px;@layout-sider-background: @primary-color;@menu-item-active-bg: @white;@menu-bg: @primary-color;@menu-item-color: @white;@font-family: 'Roboto', sans-serif;@white: #fff;@primaryHover: #fff;@dodgerBlue: #4b94f7;@warmGrey: #939393;@paleGrey: #f0f2f5;@warmGreyTwo: #707070;@black16: #29000000;@bubblegumPink: #ff80d9;@nightBlue: #06164b;@red: #ff0000;@text-color: #000000;@primaryBlue: #1000ff;@pinkishGrey: #ccc6c6;@warmGreyThree: #9f9a9a;@offYellow: #edf531;@veryLightPink: #fff3f3;@paleLilac: #e6e3ff;@news: #FF44B9;@supermarket: #F8FF44;@job: #BE4290;@transport: #CCCDB8;@tutorial: #BE4242;@rent: #42AEBE;@tourist: #D6C449;@restaurant: #42BE8C;@entertainment: #91BE42;@fonehouse: #30CB8C;@visa: #6742BE;@cosmetic: #0183E7;@tableRowHover: #f2f4f9;@disable: #c0c0c0;@black65: #595959;@warning: #FF7E0E;@success: #09CA51;@percentBlack40: rgba(0, 0, 0, 0.4);@lightGrayishNavy: #aeb5c6;@percent: rgba(255, 255, 255, 0.9);@black: #000000;@blueTag: #009cf5;@yellow: #f59b00;@green: #139a43;@orangeTag: #ff6700;@primaryShadow: #8c9ee8;@darkPastelGreen: #2FBE50;@goGreen: #15AE67;@Platinum: #ECEBEB;