.change-working-time-popup-wrapper {
  max-width: 1000px !important;
  width: 100% !important;
  display: flex;
  .ant-modal-content {
    .ant-modal-body {
      .change-working-time-popup-container {
        .change-working-time-popup-header {
          display: flex;
          align-items: center;
          justify-content: space-between;
          .header-title-container {
            display: flex;
            align-items: center;
            .icon-close {
              width: 20px;
              height: 20px;
              margin-right: 10px;
              cursor: pointer;
            }
            .header-content {
              font-weight: bold;
              font-size: 18px;
            }
          }
          .confirm-btn {
            width: 140px;
          }
        }
        .change-working-time-popup-body {
          display: flex;
          margin-top: 16px;
          .type-working-time-container {
            padding-right: 20px;
            border-right: 1px solid @border;
            .choose-type-title {
              font-size: 20px;
              font-weight: bold;
              color: @primary-color;
            }
            .working-time-options {
              display: flex;
              flex-direction: column;
              .option-item {
                display: flex;
                align-items: center;
                padding: 10px 0px;
                .option-label {
                  color: @primary-color;
                  margin-left: 10px;
                  .content {
                    font-weight: 400;
                  }
                }
              }
            }
          }
          .optional-working-time-container {
            border-left: 1px solid @border;
            padding-left: 20px;
            .working-time-img {
              width: 100%;
            }
            align-items: center;
            display: flex;
            flex-direction: column;
            .create-working-time-btn {
              margin-top: 10px;
              width: 240px;
            }
          }
          .create-custom-working-time-container {
            border-left: 1px solid @border;
            padding-left: 20px;
            .working-time-of-week-wrapper {
              display: flex;
              flex-direction: column;
              .working-time-of-day-container {
                margin: 10px 0px;
                display: flex;
                .working-time-of-day-label {
                  width: 80px;
                }
                .working-time-of-day-value {
                  .no-working-time-wrapper {
                    display: flex;
                    .close-time-label {
                      width: 120px;
                      border-radius: 8px;
                      border: 1px solid @warmGreyTwo;
                      padding: 6px 10px;
                      display: flex;
                      align-items: center;

                      .anticon-clock-circle {
                        margin-right: 10px;
                      }
                    }
                    .add-working-time-btn {
                      width: 50px;
                      margin-left: 10px;
                    }
                  }
                  .day-working-time-wrapper {
                    display: flex;
                    flex-direction: column;
                    .working-time-item-container {
                      display: flex;
                      margin-bottom: 10px;
                      .add-working-time-btn {
                        width: 50px;
                        margin-left: 10px;
                      }
                      .remove-working-time-btn {
                        width: 50px;
                        margin-left: 10px;
                      }
                      .ant-picker-range {
                        border-radius: 8px;
                        border: 1px solid @warmGreyTwo;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@primary-color: #26415d;@primaryFocus: #ae0d00;@error: #FF0D0D;@placeholder: #adaeb6;@text: #333749;@background: #f0f2f5;@border: #c7c7cc;@layout-header-height: 50px;@layout-sider-background: @primary-color;@menu-item-active-bg: @white;@menu-bg: @primary-color;@menu-item-color: @white;@font-family: 'Roboto', sans-serif;@white: #fff;@primaryHover: #fff;@dodgerBlue: #4b94f7;@warmGrey: #939393;@paleGrey: #f0f2f5;@warmGreyTwo: #707070;@black16: #29000000;@bubblegumPink: #ff80d9;@nightBlue: #06164b;@red: #ff0000;@text-color: #000000;@primaryBlue: #1000ff;@pinkishGrey: #ccc6c6;@warmGreyThree: #9f9a9a;@offYellow: #edf531;@veryLightPink: #fff3f3;@paleLilac: #e6e3ff;@news: #FF44B9;@supermarket: #F8FF44;@job: #BE4290;@transport: #CCCDB8;@tutorial: #BE4242;@rent: #42AEBE;@tourist: #D6C449;@restaurant: #42BE8C;@entertainment: #91BE42;@fonehouse: #30CB8C;@visa: #6742BE;@cosmetic: #0183E7;@tableRowHover: #f2f4f9;@disable: #c0c0c0;@black65: #595959;@warning: #FF7E0E;@success: #09CA51;@percentBlack40: rgba(0, 0, 0, 0.4);@lightGrayishNavy: #aeb5c6;@percent: rgba(255, 255, 255, 0.9);@black: #000000;@blueTag: #009cf5;@yellow: #f59b00;@green: #139a43;@orangeTag: #ff6700;@primaryShadow: #8c9ee8;@darkPastelGreen: #2FBE50;@goGreen: #15AE67;@Platinum: #ECEBEB;