.message-cell-container {
  // display: flex;
  .send-by {
    text-align: right;
    color: #AEAEAE;
    margin-top: 6px;
  }
  .status-content {
    color: @white;
    margin-left: 8px;
  }
  .status-box- {
    position: absolute;


    &active {
      background-color: #D57B14;
      position: absolute;
      border-radius:6px 0px 0px 6px;
      top: 0;
      right: 0;
      padding:6px 12px;
    }
    &ordered {
      background-color: #078436;
      position: absolute;
      border-radius:6px 0px 0px 6px;
      top: 0;
      right: 0;
      padding:6px 12px;
    }
    &removed {
      background-color:#FF0000;
      position: absolute;
      border-radius:6px 0px 0px 6px;
      top: 0;
      right: 0;
      padding:6px 12px;
    }
  }
  .promotion-box {
    background-color: red;
    border-radius:0px 0px 6px 0px;
    padding:12px 24px;
    position: absolute;
    top: 0;
    left: 0;
  }
  .text-promotion {
    color: @white;
    font-size: 18px;
    font-weight: bolder;
  }
  .product-container {
    position: relative;
    border-radius: 4px;
    box-shadow: 0 1px 0 1px rgba(0, 0, 0, 0.05);
    background-color: @white;
  }
  .product-name {
  font-size: 18px;
  font-weight: bold;
  color: @primary-color;
  }
  .product-info {
    font-size: 13px;
    color: @primary-color;
    }
    .product-price {
      font-size: 20px;
      font-weight: bolder;
      color: red;
    }
  .ant-avatar {
    border: solid 1px rgba(53, 62, 58, 0.1);
    margin-right: 8px;
  }
  .message-text-container {
    color: @black;
    width: fit-content;
    box-shadow: 0px 4px 35px #0000001A;
    word-break: break-word;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;
    padding: 12px;
    max-width: 400px;
    white-space: pre-line;
    .other-message {
      text-decoration: revert;
    }
  }
  .message-time {
    margin-top: -20px;
    font-size: 12px;
    font-weight: 500;
    color: rgba(53, 62, 58, 0.8);
    text-align: center;
    &:first-letter {
      text-transform:capitalize;
    }
  }
  .video-container {
    margin-top: 12px;
  }
}

.message-cell-container.own-cell-container {
  .message-text-container {
    border-top-right-radius: unset;
    border-top-left-radius: 8px;
    background-color: #4F7CFE;
    color: @white;
    margin-left: auto;
    .self-message {
      color: white;
      text-decoration: revert;
    }
  }
  .message-time {
    text-align: center;
  }
  #ReactGridGallery {
    display: flex;
    justify-content: flex-end;
    img {
      object-fit: cover;
    }
  }
}
@primary-color: #26415d;@primaryFocus: #ae0d00;@error: #FF0D0D;@placeholder: #adaeb6;@text: #333749;@background: #f0f2f5;@border: #c7c7cc;@layout-header-height: 50px;@layout-sider-background: @primary-color;@menu-item-active-bg: @white;@menu-bg: @primary-color;@menu-item-color: @white;@font-family: 'Roboto', sans-serif;@white: #fff;@primaryHover: #fff;@dodgerBlue: #4b94f7;@warmGrey: #939393;@paleGrey: #f0f2f5;@warmGreyTwo: #707070;@black16: #29000000;@bubblegumPink: #ff80d9;@nightBlue: #06164b;@red: #ff0000;@text-color: #000000;@primaryBlue: #1000ff;@pinkishGrey: #ccc6c6;@warmGreyThree: #9f9a9a;@offYellow: #edf531;@veryLightPink: #fff3f3;@paleLilac: #e6e3ff;@news: #FF44B9;@supermarket: #F8FF44;@job: #BE4290;@transport: #CCCDB8;@tutorial: #BE4242;@rent: #42AEBE;@tourist: #D6C449;@restaurant: #42BE8C;@entertainment: #91BE42;@fonehouse: #30CB8C;@visa: #6742BE;@cosmetic: #0183E7;@tableRowHover: #f2f4f9;@disable: #c0c0c0;@black65: #595959;@warning: #FF7E0E;@success: #09CA51;@percentBlack40: rgba(0, 0, 0, 0.4);@lightGrayishNavy: #aeb5c6;@percent: rgba(255, 255, 255, 0.9);@black: #000000;@blueTag: #009cf5;@yellow: #f59b00;@green: #139a43;@orangeTag: #ff6700;@primaryShadow: #8c9ee8;@darkPastelGreen: #2FBE50;@goGreen: #15AE67;@Platinum: #ECEBEB;