.register-list-wrapper {
  background-color: @white;
  height: 100%;
  padding: 16px;
  .register-list-header {
    .ant-typography {
      margin-bottom: 0;
    }
  }
  .content-container {
    .see-more-btn {
      border: none;
    }
    .search-bar-form {
      margin-top: 16px;
      display: flex;
      justify-content: space-between;
      .text-field-container {
        width: 100%;
        .ant-input-affix-wrapper {
          border: none;
        }
      }
    }
  }
}

.business-info-wrapper {
  padding: 16px;
  .backButton {
    margin-right: 20px;
  }
  .row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .user-info-header {
    height: 40px;
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    font-weight: bold;
    font-size: 20px;
    .icon-back {
      width: 20px;
      height: 20px;
      margin-right: 10px;
    }
  }
  .business-info-row-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
  .business-item {
    width: 100%;
    padding: 20px 20px;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 8px;
  }
  .business-value {
    color: #26415d;
    line-height: 20px;
    font-weight: bold;
    margin-top: 10px;
  }
  .business-banner-wrapper {
    width: 100%;
    padding: 20px 20px;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 8px;
  }
  .marginTop20 {
    margin-top: 20px;
  }
  .marginRight40 {
    margin-right: 80px;
  }
  .marginBottom20 {
    margin-bottom: 20px;
  }
  .paddingHorizon20 {
    margin-left: 20px;
    margin-right: 20px;
  }
  .user-info-follower-decentralization-wrapper {
    width: 100%;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 8px;
    flex-direction: row;
    justify-content: space-between;
    padding: 16px;
    display: flex;
    .break-line {
      width: 2px;
      height: auto;
      margin-top: 20px;
      background-color: #26415d;
    }
    .user-info-basic-wrapper {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding-right: 20px;
      .info-basic-title {
        color: #26415d;
        line-height: 20px;
        font-weight: bold;
      }
      .info-basic-detail {
        margin-top: 10px;
        justify-content: space-between;
        display: flex;
        align-items: center;
        .user-avatar {
          min-width: 48px;
          img {
            width: 48px;
            height: 48px;
            object-fit: cover;
            border-radius: 50%;
          }
        }
        .user-info {
          margin-left: 8px;
          .user-name {
            color: black;
            font-weight: bold;
            font-size: 17px;
          }
          .user-contact {
            white-space: pre-line;
            font-size: 14px;
            color: #26415d;
          }
        }
        .user-basic {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          .item-info-wrapper {
            display: flex;
            width: 50%;
            margin-top: 10px;
            .icon-info {
              width: 32px;
              height: 32px;
              border-radius: 50%;
              margin-right: 2px;
            }
            .item-info-detail-wrapper {
              margin: 0px 4px;
              padding-bottom: 4px;
              border-bottom: 1px #9f9a9a solid;
              flex: 1;
              .item-value {
                font-size: 14px;
                line-height: 10px;
                color: #26415d;
              }
              .item-name {
                margin-top: 6px;
                font-size: 12px;
                // line-height: 10px;
                color: #9f9a9a;
              }
            }
          }
        }
      }
    }
    .user-info-decentralization-wrapper {
      padding-left: 20px;
      width: 40%;
      .info-decentralization-title {
        color: #26415d;
        line-height: 20px;
        font-weight: bold;
        text-align: center;
      }
      .info-decentralization-list {
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        max-height: 100px;
        .ant-empty-normal {
          margin: 10px 0px;
        }
      }
    }
  }
  .item-role {
    display: flex;
    align-items: center;
    margin-top: 10px;
    .type-role {
      width: 18px;
      height: 18px;
      border-radius: 4px;
      margin-right: 4px;
    }
    .news-role {
      background-color: #ff44b9;
    }
    .supermarket-role {
      background-color: #f8ff44;
    }
    .transport-role {
      background-color: #cccdb8;
    }
    .jobs-role {
      background-color: #be4290;
    }
    .tutorial-role {
      background-color: #be4242;
    }
    .rent-role {
      background-color: #42aebe;
    }
    .tourist-role {
      background-color: #d6c449;
    }
    .visa-role {
      background-color: #6742be;
    }
    .restaurant-role {
      background-color: #42be8c;
    }
    .entertainment-role {
      background-color: #91be42;
    }
    .fonehouse-role {
      background-color: #30cb8c;
    }
    .cosmetic-role {
      background-color: #0183E7;
    }
    .car_and_old_stuff-role {
      background-color: #CB8930;
    }
    .type-text {
      font-size: 12px;
    }
  }
}

@primary-color: #26415d;@primaryFocus: #ae0d00;@error: #FF0D0D;@placeholder: #adaeb6;@text: #333749;@background: #f0f2f5;@border: #c7c7cc;@layout-header-height: 50px;@layout-sider-background: @primary-color;@menu-item-active-bg: @white;@menu-bg: @primary-color;@menu-item-color: @white;@font-family: 'Roboto', sans-serif;@white: #fff;@primaryHover: #fff;@dodgerBlue: #4b94f7;@warmGrey: #939393;@paleGrey: #f0f2f5;@warmGreyTwo: #707070;@black16: #29000000;@bubblegumPink: #ff80d9;@nightBlue: #06164b;@red: #ff0000;@text-color: #000000;@primaryBlue: #1000ff;@pinkishGrey: #ccc6c6;@warmGreyThree: #9f9a9a;@offYellow: #edf531;@veryLightPink: #fff3f3;@paleLilac: #e6e3ff;@news: #FF44B9;@supermarket: #F8FF44;@job: #BE4290;@transport: #CCCDB8;@tutorial: #BE4242;@rent: #42AEBE;@tourist: #D6C449;@restaurant: #42BE8C;@entertainment: #91BE42;@fonehouse: #30CB8C;@visa: #6742BE;@cosmetic: #0183E7;@tableRowHover: #f2f4f9;@disable: #c0c0c0;@black65: #595959;@warning: #FF7E0E;@success: #09CA51;@percentBlack40: rgba(0, 0, 0, 0.4);@lightGrayishNavy: #aeb5c6;@percent: rgba(255, 255, 255, 0.9);@black: #000000;@blueTag: #009cf5;@yellow: #f59b00;@green: #139a43;@orangeTag: #ff6700;@primaryShadow: #8c9ee8;@darkPastelGreen: #2FBE50;@goGreen: #15AE67;@Platinum: #ECEBEB;