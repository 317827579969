// @import 'styles/theme';
.login-page-wrapper {
  height: 100vh;
  background: url('/assets/images/login-background.jpg');
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-size: cover;
  background-repeat: no-repeat;
  .logo-wrapper {
    margin: 0 auto;
    img {
      width: 200px;
      height: 200px;
    }
  }
  .login-text {
    font-size: 16px;
    font-weight: bold;
    font-weight: bold;
    font-size: 16px;
    line-height: 33px;
    color: @warmGreyTwo;
  }
  .otp-input-container {
    margin-top: 8px;
  }
  .login-form {
    width: 100%;
    max-width: 700px;
    padding: 34px 32px 46px;
    background-color: @white;
    margin: 0 auto;
    font: normal normal bold 29px/33px Helvetica Neue;
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.15);
  }
  .submit-btn {
    height: 48px;
    width: 100%;
    max-width: 100%;
    font-size: 16px;
    font-weight: 600;
    margin-top: 16px;
  }
  .resend-otp-btn-container {
    margin-top: 16px;
    button {
      width: unset;
      margin-left: auto;
    }
  }
  .forgot-password-text {
    margin-top: 16px;
    text-align: center;
  }
  .phone-title {
    margin-bottom: 8px;
    font-size: 18px;
  }
  .phone-container {
    display: flex;
    justify-content: space-between;
    .country-code {
      width: 30%;
    }
    .phone-number {
      width: 68%;
    }
  }
}
@primary-color: #26415d;@primaryFocus: #ae0d00;@error: #FF0D0D;@placeholder: #adaeb6;@text: #333749;@background: #f0f2f5;@border: #c7c7cc;@layout-header-height: 50px;@layout-sider-background: @primary-color;@menu-item-active-bg: @white;@menu-bg: @primary-color;@menu-item-color: @white;@font-family: 'Roboto', sans-serif;@white: #fff;@primaryHover: #fff;@dodgerBlue: #4b94f7;@warmGrey: #939393;@paleGrey: #f0f2f5;@warmGreyTwo: #707070;@black16: #29000000;@bubblegumPink: #ff80d9;@nightBlue: #06164b;@red: #ff0000;@text-color: #000000;@primaryBlue: #1000ff;@pinkishGrey: #ccc6c6;@warmGreyThree: #9f9a9a;@offYellow: #edf531;@veryLightPink: #fff3f3;@paleLilac: #e6e3ff;@news: #FF44B9;@supermarket: #F8FF44;@job: #BE4290;@transport: #CCCDB8;@tutorial: #BE4242;@rent: #42AEBE;@tourist: #D6C449;@restaurant: #42BE8C;@entertainment: #91BE42;@fonehouse: #30CB8C;@visa: #6742BE;@cosmetic: #0183E7;@tableRowHover: #f2f4f9;@disable: #c0c0c0;@black65: #595959;@warning: #FF7E0E;@success: #09CA51;@percentBlack40: rgba(0, 0, 0, 0.4);@lightGrayishNavy: #aeb5c6;@percent: rgba(255, 255, 255, 0.9);@black: #000000;@blueTag: #009cf5;@yellow: #f59b00;@green: #139a43;@orangeTag: #ff6700;@primaryShadow: #8c9ee8;@darkPastelGreen: #2FBE50;@goGreen: #15AE67;@Platinum: #ECEBEB;