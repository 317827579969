.location-list-modal-wrapper {
  .modal-header {
    display: flex;
    align-items: center;
    font-weight: bold;
    font-size: 18px;
    margin-bottom: 16px;
    .icon-close {
      width: 20px;
      height: 20px;
      margin-right: 10px;
      cursor: pointer;
    }
  }
  .add-location-input {
    .ant-input-wrapper {
      display: flex;
      .ant-input {
        flex: 1 1 auto;
        border-radius: 8px 0 0 8px;
        font-size: 16px;
      }
      .ant-input-group-addon {
        flex: 0 0 120px;
        border-radius: 0 8px 8px 0;
      }
    }
  }
  .location-list-wrapper {
    .location-item {
      display: flex;
      align-items: center;
      margin-top: 8px;
      &:first-of-type {
        margin-top: 16px;
      }
      .ant-typography {
        flex: 1 1 auto;
        margin: 0;
        left: 0;
        word-break: break-all;
      }
      button {
        flex: 0 0 32px;
        height: 32px;
        padding: 0;
        border-radius: 100%;
      }
    }
  }
}
@primary-color: #26415d;@primaryFocus: #ae0d00;@error: #FF0D0D;@placeholder: #adaeb6;@text: #333749;@background: #f0f2f5;@border: #c7c7cc;@layout-header-height: 50px;@layout-sider-background: @primary-color;@menu-item-active-bg: @white;@menu-bg: @primary-color;@menu-item-color: @white;@font-family: 'Roboto', sans-serif;@white: #fff;@primaryHover: #fff;@dodgerBlue: #4b94f7;@warmGrey: #939393;@paleGrey: #f0f2f5;@warmGreyTwo: #707070;@black16: #29000000;@bubblegumPink: #ff80d9;@nightBlue: #06164b;@red: #ff0000;@text-color: #000000;@primaryBlue: #1000ff;@pinkishGrey: #ccc6c6;@warmGreyThree: #9f9a9a;@offYellow: #edf531;@veryLightPink: #fff3f3;@paleLilac: #e6e3ff;@news: #FF44B9;@supermarket: #F8FF44;@job: #BE4290;@transport: #CCCDB8;@tutorial: #BE4242;@rent: #42AEBE;@tourist: #D6C449;@restaurant: #42BE8C;@entertainment: #91BE42;@fonehouse: #30CB8C;@visa: #6742BE;@cosmetic: #0183E7;@tableRowHover: #f2f4f9;@disable: #c0c0c0;@black65: #595959;@warning: #FF7E0E;@success: #09CA51;@percentBlack40: rgba(0, 0, 0, 0.4);@lightGrayishNavy: #aeb5c6;@percent: rgba(255, 255, 255, 0.9);@black: #000000;@blueTag: #009cf5;@yellow: #f59b00;@green: #139a43;@orangeTag: #ff6700;@primaryShadow: #8c9ee8;@darkPastelGreen: #2FBE50;@goGreen: #15AE67;@Platinum: #ECEBEB;