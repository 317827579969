.news-summary-tooltip-wrapper {
  border-radius: 8px;
  background-color: @white;
  padding-right: 10px;
  padding-left: 10px;
  position: relative;
  .news-summary-title {
    color: @primary-color;
    font-weight: bold;
    margin-bottom: 10px;
    .bold {
      font-weight: bold;
    }
  }
  .news-summary-content {
    color: @text-color;
    display: -webkit-box;
    -webkit-line-clamp: 6;
    -webkit-box-orient: vertical;
    overflow: hidden;
    word-break: break-all;
    img {
      display: none;
    }
  }
  .news-summary-thumbnail {
    max-width: 480px;
    width: 100%;
    img {
      max-width: 100%;
      border-radius: 4px;
      border: thin solid #b9b9b9;
    }
  }
  .see-detail-btn {
    color: @primary-color;
    font-weight: bold;
    text-align: end;
    padding: 10px 0px;
  }
}

.marginLeft20 {
  margin-left: 20px;
}

.market-product-summary-tooltip-wrapper {
  border-radius: 8px;
  background-color: @white;
  padding: 10px;
  position: relative;
  .market-product-row {
    display: flex;
    align-items: flex-start;
    .market-product-shop-thumbnail {
      position: relative;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 4px;
      }
      .promotion {
        background-color: @error;
        padding: 4px;
        position: absolute;
        top: 0;
        font-size: 12px;
        border-radius: 4px;
      }
    }
    .market-product-shop-detail {
      flex: 1;
      color: @primary-color;
      margin: 0px 10px;
      .name-shop {
        font-weight: bold;
        font-size: 16px;
      }
      .address-shop {
        font-size: 14px;
      }
      .opening-closing-time {
        font-size: 14px;
      }
    }
    .shop-status {
      width: 140px;
      font-weight: bold;
      font-size: 24px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .shop-opening {
      color: @darkPastelGreen;
    }
    .shop-closed {
      color: @red;
    }
    .shop-lock {
      color: @red;
      font-weight: bold;
    }
  }
}

.entertainment-product-summary-tooltip-wrapper {
  border-radius: 8px;
  background-color: @white;
  padding: 10px;
  position: relative;
  .entertainment-product-row {
    display: flex;
    align-items: flex-start;
    .entertainment-product-shop-thumbnail {
      position: relative;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 4px;
      }
      .promotion {
        background-color: @error;
        padding: 4px;
        position: absolute;
        top: 0;
        font-size: 12px;
        border-radius: 4px;
      }
    }
    .entertainment-product-shop-detail {
      flex: 1;
      color: @primary-color;
      margin: 0px 10px;
      .name-shop {
        font-weight: bold;
        font-size: 16px;
      }
      .address-shop {
        font-size: 14px;
      }
      .opening-closing-time {
        font-size: 14px;
      }
    }
    .shop-status {
      width: 140px;
      font-weight: bold;
      font-size: 24px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .shop-opening {
      color: @darkPastelGreen;
    }
    .shop-closed {
      color: @red;
    }
  }
}

.restaurant-product-summary-tooltip-wrapper {
  border-radius: 8px;
  background-color: @white;
  padding: 10px;
  position: relative;
  .restaurant-product-row {
    display: flex;
    align-items: flex-start;
    .restaurant-product-shop-thumbnail {
      position: relative;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 4px;
      }
      .promotion {
        background-color: @error;
        padding: 4px;
        position: absolute;
        top: 0;
        font-size: 12px;
        border-radius: 4px;
      }
    }
    .restaurant-product-shop-detail {
      flex: 1;
      color: @primary-color;
      margin: 0px 10px;
      .name-shop {
        font-weight: bold;
        font-size: 16px;
      }
      .address-shop {
        font-size: 14px;
      }
      .opening-closing-time {
        font-size: 14px;
      }
    }
    .shop-status {
      width: 140px;
      font-weight: bold;
      font-size: 24px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .shop-opening {
      color: @darkPastelGreen;
    }
    .shop-closed {
      color: @red;
    }
  }
}

.cosmetic-product-summary-tooltip-wrapper {
  border-radius: 8px;
  background-color: @white;
  padding: 10px;
  position: relative;
  .cosmetic-product-row {
    display: flex;
    align-items: flex-start;
    .cosmetic-product-shop-thumbnail {
      position: relative;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 4px;
      }
      .promotion {
        background-color: @error;
        padding: 4px;
        position: absolute;
        top: 0;
        font-size: 12px;
        border-radius: 4px;
      }
    }
    .cosmetic-product-shop-detail {
      flex: 1;
      color: @primary-color;
      margin: 0px 10px;
      .name-shop {
        font-weight: bold;
        font-size: 16px;
      }
      .address-shop {
        font-size: 14px;
      }
      .opening-closing-time {
        font-size: 14px;
      }
    }
    .shop-status {
      width: 140px;
      font-weight: bold;
      font-size: 24px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .shop-opening {
      color: @darkPastelGreen;
    }
    .shop-closed {
      color: @red;
    }
  }
}

.store-summary-tooltip-wrapper {
  border-radius: 8px;
  background-color: @white;
  padding: 10px;
  position: relative;
  .store-row {
    display: flex;
    .store-shop-thumbnail {
      width: 240px;
      height: 120px;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 2px;
      }
    }
    .store-shop-detail {
      flex: 1;
      color: @primary-color;
      margin: 0px 10px;
      .name-shop {
        font-weight: bold;
        font-size: 16px;
      }
      .address-shop {
        font-size: 14px;
      }
      .opening-closing-time {
        font-size: 14px;
      }
    }
    .shop-status {
      width: 140px;
      font-weight: bold;
      font-size: 24px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .shop-opening {
      color: @darkPastelGreen;
      font-weight: bold;
    }
    .shop-lock {
      color: @red;
      font-weight: bold;
    }
    .shop-closed {
      color: @red;
      font-weight: bold;
    }
  }
}

.sim-summary-tooltip-wrapper {
  border-radius: 8px;
  background-color: @white;
  color: @primary-color;
  padding: 10px;
  position: relative;
  font-size: 16px;
}

.store-photo-summary-tooltip-wrapper {
  border-radius: 8px;
  background-color: @white;
  color: @primary-color;
  padding: 10px;
  position: relative;
  font-size: 16px;
  display: flex;
  gap: 16px;
  & > * {
    flex: 0 1 50%;
  }
}
@primary-color: #26415d;@primaryFocus: #ae0d00;@error: #FF0D0D;@placeholder: #adaeb6;@text: #333749;@background: #f0f2f5;@border: #c7c7cc;@layout-header-height: 50px;@layout-sider-background: @primary-color;@menu-item-active-bg: @white;@menu-bg: @primary-color;@menu-item-color: @white;@font-family: 'Roboto', sans-serif;@white: #fff;@primaryHover: #fff;@dodgerBlue: #4b94f7;@warmGrey: #939393;@paleGrey: #f0f2f5;@warmGreyTwo: #707070;@black16: #29000000;@bubblegumPink: #ff80d9;@nightBlue: #06164b;@red: #ff0000;@text-color: #000000;@primaryBlue: #1000ff;@pinkishGrey: #ccc6c6;@warmGreyThree: #9f9a9a;@offYellow: #edf531;@veryLightPink: #fff3f3;@paleLilac: #e6e3ff;@news: #FF44B9;@supermarket: #F8FF44;@job: #BE4290;@transport: #CCCDB8;@tutorial: #BE4242;@rent: #42AEBE;@tourist: #D6C449;@restaurant: #42BE8C;@entertainment: #91BE42;@fonehouse: #30CB8C;@visa: #6742BE;@cosmetic: #0183E7;@tableRowHover: #f2f4f9;@disable: #c0c0c0;@black65: #595959;@warning: #FF7E0E;@success: #09CA51;@percentBlack40: rgba(0, 0, 0, 0.4);@lightGrayishNavy: #aeb5c6;@percent: rgba(255, 255, 255, 0.9);@black: #000000;@blueTag: #009cf5;@yellow: #f59b00;@green: #139a43;@orangeTag: #ff6700;@primaryShadow: #8c9ee8;@darkPastelGreen: #2FBE50;@goGreen: #15AE67;@Platinum: #ECEBEB;