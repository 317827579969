@import '~antd/dist/antd.less';
@import './theme.less';

html { height:100%; }

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f0f2f5
}

* {
  box-sizing: border-box;
}

.app-container {
  min-height: 100vh;
  width: 100%;
}

.content-wrapper {
  padding: 24px;
}
.full-flex {
  display: flex;
  justify-content: center;
  align-items: center;
}

.ant-input-affix-wrapper-focused, .ant-select-focused {
  box-shadow: none;
}

.ant-divider {
  margin: 16px 0;
}

.ant-typography {
  margin-bottom: 0;
}

table {
  .ant-table-thead > tr > th {
    border: unset;
  }
  .ant-table-tbody > tr > td {
    height: 22px;
    padding: 15px;
    color: @black;
    cursor: pointer;
    overflow-wrap: break-word;
    span {
      cursor: auto !important;
    }
    div {
      cursor: auto !important;
    }
  }
}

.ant-table-tbody > tr.ant-table-row:hover > td {
  background-color: @tableRowHover;
}

.ant-table-pagination.ant-pagination {
    margin: 16px 0 0;
}
.ant-pagination {
  .ant-pagination-total-text {
    margin-right: 16px;
    color: @black;
  }
  .ant-pagination-item {
    border-radius: 6px;
    border-color: @disable;
    a {
      color: @black65;
    }
  }
  .ant-pagination-item-active {
    background-color: @primary-color;
    border: unset;
    a {
      color: @white;
      line-height: 32px;
    }
  }
  .ant-pagination-item-link {
    border-radius: 6px;
  }
}

.ant-tooltip-open {
  background-color: transparent;
}

.ant-table-tbody > tr.ant-table-row-selected > td {
  background-color: #f0f2f5;
}

* {
  box-sizing: border-box;
  font-family: 'Roboto', sans-serif;
}

body {
  font-size: 14px;
  line-height: 1.5;
  color: #24292e;
  background-color: #fff;
}

.app {
  margin: 2rem;
}

a {
  color: #08c;
}


input {
  padding: .5rem;
  font-size: 16px;
  width: 100%;
  display: block;
  border-radius: 4px;
  border: 1px solid #ccc;
}

input:focus {
  border-color: #007eff;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
    0 0 0 3px rgba(0, 126, 255, 0.1);
  outline: none;
}

input.error {
  border-color: red;
}

label {
  font-weight: bold;
  display: block;
  margin-bottom: .5rem;
}

.input-feedback {
  color: red;
  margin-top: .25rem;
}

.ant-btn {
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  border-radius: 4px;
  padding: 8px 16px;
  width: 100%;
}
button:disabled {
  opacity: .5;
  cursor: not-allowed !important;
}

button + button {
  margin-left: .5rem;
}

button.outline {
  background-color: #eee;
  border: 1px solid #aaa;
  color: #555;
}
.bold {
  font-weight: bold;
}
.cancel-button {
  background-color: @error;
  color: @white;
  &:hover {
    background-color: @error;
    color: @white;
  }
}

.l-title-text {
  font-size: 24px;
  font-weight: bold;
  line-height: normal;
  color: @primary-color;
}

.m-title-text {
  font-size: 18px;
  font-weight: 700;
  line-height: 1.78;
  color: @primary-color;
}

.s-text {
  font-size: 12px;
  line-height: 1.67;
  color: @primary-color;
}

.ant-tag-success {
  color: @success;
  border-color: @success;
}
input {
  &:-webkit-autofill::first-line,
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    font-size: 18px;
    font-family: 'Noto Sans JP', sans-serif;
    transition: background-color 5000s ease-in-out 0s;
    background-clip: content-box !important;
    box-shadow: 0 0 0 30px @white inset !important;
    -webkit-box-shadow: 0 0 0 30px @white inset !important;
  }
}

.ant-input {
  color: @black;
}


.ant-btn.ant-btn-primary.ant-btn-lg {
  box-shadow: 0 25px 20px -20px @primaryShadow;
}
.ant-btn.ant-btn-primary.ant-btn-lg:disabled {
  box-shadow: none;
}

.ant-btn-sm {
  height: 30px;
}

.ant-btn-lg {
  height: 48px;
  font-weight: 500;
  font-size: 18px;
}

.ant-btn-icon-only.ant-btn-lg {
  height: 48px;
  width: 48px;
}

.ant-input-affix-wrapper-focused, .ant-select-focused {
  box-shadow: none;
}

table {
  .ant-table-thead > tr > th {
    border: unset;
  }
  .ant-table-tbody > tr > td {
    height: 22px;
    padding: 15px;
    color: @black;
    cursor: pointer;
    span {
      cursor: auto !important;
    }
    div {
      cursor: auto !important;
    }
  }
}

.ant-table-tbody > tr.ant-table-row:hover > td {
  background-color: @tableRowHover;
}

.ant-table-pagination.ant-pagination {
    margin: 16px 0 0;
}
.ant-pagination {
  .ant-pagination-total-text {
    white-space: nowrap;
    margin-right: 16px;
    color: @black;
  }
  .ant-pagination-item {
    border-radius: 6px;
    border-color: @disable;
    a {
      color: @black65;
    }
    @media only screen and (max-width: 320px) {
      display: none;
      &.ant-pagination-item-active {
        display: unset;
      }
    }
  }
  .ant-pagination-item-active {
    background-color: @primary-color;
    border: unset;
    a {
      color: @white;
      line-height: 32px;
    }
  }
  .ant-pagination-item-link {
    border-radius: 6px;
  }
}


.ant-tag-error {
  color: @red;
  border-color: @red;
}

.ant-tag-warning {
  color: @warning;
  border-color: @warning;
}

.cancel-button {
  background-color: @error;
  &:hover, &:focus {
    background-color: @error;
    border-color: @error;
    color: @white;
  }
}

.ellipsis-text-one-line {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  word-break: break-all;
}

.flex {
  display: flex;
  &.flex-end {
    justify-content: flex-end;
  }
  &.flex-between {
    justify-content: space-between;
  }
  &.items-center {
    align-items: center;
  }
}
@primary-color: #26415d;@primaryFocus: #ae0d00;@error: #FF0D0D;@placeholder: #adaeb6;@text: #333749;@background: #f0f2f5;@border: #c7c7cc;@layout-header-height: 50px;@layout-sider-background: @primary-color;@menu-item-active-bg: @white;@menu-bg: @primary-color;@menu-item-color: @white;@font-family: 'Roboto', sans-serif;@white: #fff;@primaryHover: #fff;@dodgerBlue: #4b94f7;@warmGrey: #939393;@paleGrey: #f0f2f5;@warmGreyTwo: #707070;@black16: #29000000;@bubblegumPink: #ff80d9;@nightBlue: #06164b;@red: #ff0000;@text-color: #000000;@primaryBlue: #1000ff;@pinkishGrey: #ccc6c6;@warmGreyThree: #9f9a9a;@offYellow: #edf531;@veryLightPink: #fff3f3;@paleLilac: #e6e3ff;@news: #FF44B9;@supermarket: #F8FF44;@job: #BE4290;@transport: #CCCDB8;@tutorial: #BE4242;@rent: #42AEBE;@tourist: #D6C449;@restaurant: #42BE8C;@entertainment: #91BE42;@fonehouse: #30CB8C;@visa: #6742BE;@cosmetic: #0183E7;@tableRowHover: #f2f4f9;@disable: #c0c0c0;@black65: #595959;@warning: #FF7E0E;@success: #09CA51;@percentBlack40: rgba(0, 0, 0, 0.4);@lightGrayishNavy: #aeb5c6;@percent: rgba(255, 255, 255, 0.9);@black: #000000;@blueTag: #009cf5;@yellow: #f59b00;@green: #139a43;@orangeTag: #ff6700;@primaryShadow: #8c9ee8;@darkPastelGreen: #2FBE50;@goGreen: #15AE67;@Platinum: #ECEBEB;